import { createProject, getAllProjects, editProject } from '@/services/projects-api.service'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import { computed, unref } from 'vue'
import { useStore } from 'vuex'

export function useProjects(boardId) {
  const { getters } = useStore()
  const queryClient = useQueryClient()

  const queryKey = computed(() => [
    'projects',
    {
      uid: getters.uid,
      boardId: unref(boardId)
    }
  ])

  const { data, isPending } = useQuery({
    queryKey,
    queryFn: () => getAllProjects({ boardId: unref(boardId) })
  })

  const { mutateAsync: create, isPending: isCreating } = useMutation({
    mutationFn: ({ name, filters, uids }) =>
      createProject({
        boardId: unref(boardId),
        name,
        filters,
        uids
      }),

    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: queryKey.value })
      return data
    }
  })

  const { mutateAsync: edit, isPending: isEditing } = useMutation({
    mutationFn: ({ projectId, filters, uids }) =>
      editProject({
        boardId: unref(boardId),
        projectId,
        filters,
        uids
      }),

    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: queryKey.value })
      return data
    }
  })

  function isMainProjectBoard(boardId) {
    return data.value?.find((project) => project.planBoardId === boardId) || false
  }

  return {
    /**
     * Create a project
     * @param {Object} project - The project object to create { name }
     * @param {Array} filters - The filters used to include people in the project
     * @param {Array} uids - The members to add to the project (uids)
     */
    create,
    isCreating,
    edit,
    isEditing,
    projects: data,
    isLoading: isPending,
    isMainProjectBoard
  }
}
