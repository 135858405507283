export const MappingDict = {
  currency: 'currency',
  customFields: 'customFields',
  department: 'department',
  email: 'email',
  employeeId: 'employeeId',
  employeeStatus: 'employeeStatus',
  indirectConnections: 'indirectConnections',
  managerId: 'managerId',
  name: 'name',
  officeLocation: 'officeLocation',
  payPer: 'payPer',
  performance: 'performance',
  photoURL: 'photoURL',
  role: 'role',
  salary: 'salary',
  salaryType: 'salaryType',
  startDate: 'startDate',
  succession: 'succession',
  terminationDate: 'terminationDate',
  terminationReasons: 'terminationReasons',
  position: 'position',
  skill: 'skill',
  personId: 'personId'
}

export const Mapping = [
  MappingDict.currency,
  MappingDict.customFields,
  MappingDict.department,
  MappingDict.email,
  MappingDict.employeeId,
  MappingDict.employeeStatus,
  MappingDict.indirectConnections,
  MappingDict.managerId,
  MappingDict.name,
  MappingDict.officeLocation,
  MappingDict.payPer,
  MappingDict.performance,
  MappingDict.photoURL,
  MappingDict.role,
  MappingDict.salary,
  MappingDict.salaryType,
  MappingDict.startDate,
  MappingDict.succession,
  MappingDict.terminationDate,
  MappingDict.terminationReasons,
  MappingDict.skill
]

export const dateFormats = [
  'yyyy-MM-dd',
  'dd/MM/yyyy',
  'MM/dd/yyyy',
  'dd/MM/yy',
  'MM/dd/yy',
  'dd-MMM-yyyy',
  'yyyy-MMM-dd',
  'dd.MM.yyyy',
  'yyyy.MM.dd',
  'MMMM dd, yyyy',
  'dd MMMM yyyy'
]

export const mappingCategoriesDict = {
  structure: 'structure',
  compensation: 'compensation',
  other: 'other'
}

export const mappingCategories = {
  1: {
    name: 'Structure',
    id: mappingCategoriesDict.structure
  },
  2: {
    name: 'Compensation',
    id: mappingCategoriesDict.compensation
  },
  3: {
    name: 'Other',
    id: mappingCategoriesDict.other
  }
}

export const integrationColumns = {
  employeeId: {
    value: '',
    label: 'Employee ID / Unique Identifier',
    tooltipOptions: {
      title: 'Employee ID / Unique Identifier',
      content: 'Employee ID or Unique Identifier that is used to identify the employee.',
      mandatory: true
    },
    category: mappingCategoriesDict.structure
  },
  managerId: {
    value: '',
    label: "Manager's Employee ID",
    tooltipOptions: {
      title: `Manager's Employee ID`,
      content: `Employee ID or Unique Identifier that is used to identify the manager. This is the Manager's Employee ID.`,
      mandatory: true
    },
    category: mappingCategoriesDict.structure
  },
  indirectConnections: {
    value: '',
    label: "Dotted Line Connection's Employee ID"
  },
  name: {
    value: '',
    label: 'Employee Name',
    tooltipOptions: {
      title: 'Employee Name',
      content:
        'Employee Name that is used to identify the employee consisting of First name and/or Last name. For instance, John Doe.'
    }
  },
  role: {
    value: '',
    label: 'Job Title',
    tooltipOptions: {
      title: 'Job Title',
      content: 'The job title of the employee, ie: Software Engineer, Sales Manager, etc.',
      mandatory: true
    }
  },
  email: {
    value: '',
    label: 'Email'
  },
  photoURL: {
    value: '',
    label: 'Profile Image Link'
  },
  officeLocation: {
    value: '',
    label: 'Office Location'
  },
  department: {
    value: '',
    label: 'Department'
  },
  employeeStatus: {
    value: '',
    label: 'Employee Status',
    tooltipOptions: {
      title: 'Employee Status',
      content:
        'Status of the employee in the Company. For instance, Active, Inactive, Terminated, etc.'
    }
  },
  salary: {
    value: '',
    label: 'Salary',
    category: mappingCategoriesDict.compensation
  },
  currency: {
    value: '',
    label: 'Currency',
    category: mappingCategoriesDict.compensation
  },
  payPer: {
    value: '',
    label: 'Pay Per',
    tooltipOptions: {
      title: 'Pay Per',
      content: 'Pay cycle. e.g. weekly, biweekly, monthly'
    },
    category: mappingCategoriesDict.compensation
  },
  salaryType: {
    value: '',
    label: 'Salary Type',
    tooltipOptions: {
      title: 'Salary Type',
      content:
        '"Hourly", "Salary", "Commission", "Exception Hourly", "Monthly", "Piece Rate", "Contract" or "Daily"'
    },
    category: mappingCategoriesDict.compensation
  },
  performance: {
    value: [],
    label: 'Performance Indicators',
    additionalInfo: 'Tip: You can select multiple columns.',
    tooltipOptions: {
      title: 'Performance Indicators',
      content:
        'Performance Indicators of the employee in the Company. Select the field that measures the performance of the employee. For instance, 5, 4, 3, 2, 1, etc'
    },
    multiple: true,
    emptyValue: []
  },
  startDate: {
    value: '',
    label: 'Start Date',
    format: 'yyyy-MM-dd',
    labelSubtext:
      'Please select the date format that matches your data or it will default to YYYY-MM-DD',
    tooltipOptions: {
      title: 'Start Date',
      content:
        'Start Date is the hiring date of the employee in the Company in format YYYY-MM-DD. For instance, 2020-01-01.'
    }
  },
  terminationDate: {
    value: '',
    label: 'Termination Date',
    format: 'yyyy-MM-dd',
    labelSubtext:
      'Please select the date format that matches your data or it will default to YYYY-MM-DD',
    tooltipOptions: {
      title: 'Termination Date',
      content:
        'Termination Date is the date on which the employee was terminated from the Company in format YYYY-MM-DD. For instance, 2020-01-01.'
    }
  },
  terminationReasons: {
    value: [],
    label: 'Termination Reason',
    labelText: 'Select the column that specifies a termination reason.',
    labelSubtext:
      'Please ensure that you use the words "Voluntary" and "Involuntary" while specifying the reason.',
    additionalInfo: 'Tip: You can select multiple columns.',
    tooltipOptions: {
      title: 'Termination Reason',
      content:
        'Please ensure that you use the words (Voluntary) and (Involuntary) while specifying the reason.'
    },
    multiple: true,
    emptyValue: []
  },
  customFields: {
    value: [],
    label: 'Custom Fields',
    additionalInfo: 'Tip: You can select multiple columns.',
    labelText: "Select other columns you'd like to import.",
    multiple: true,
    emptyValue: []
  },
  succession: {
    value: '',
    label: 'Succession',
    labelText: 'Select column containing succession data.',
    additionalInfo: 'Check help center for information about the format'
  },
  skill: {
    value: '',
    label: 'Skill',
    labelText: 'Select column containing skill data.',
    additionalInfo: 'Check help center for information about the format'
  }
}
export const exemptMappingRequirement = [
  MappingDict.startDate,
  MappingDict.terminationDate,
  MappingDict.position
]

export const BAMBOO_HR_FIELDS = [
  'firstName',
  'lastName',
  'id',
  'city',
  'country',
  'location',
  'bestEmail',
  'bonusAmount',
  'bonusComment',
  'bonusDate',
  'bonusReason',
  'commissionAmount',
  'commissionComment',
  'commissionDate',
  'department',
  'division',
  'employeeNumber',
  'employmentHistoryStatus',
  'gender',
  'hireDate',
  'jobTitle',
  'payRate',
  'currency',
  'payRateEffectiveDate',
  'payType',
  'payPer',
  'standardHoursPerWeek',
  'status',
  'supervisor',
  'supervisorId',
  'terminationDate',
  'workEmail',
  'canUploadPhoto',
  'displayName',
  'photoUploaded',
  'photoUrl',
  'preferredName',
  'pronouns',
  'workPhone',
  'workPhoneExtension'
]
