import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

const LOCAL_HOST = 'http://localhost:3001/v1'
// const LOCAL_HOST = 'https://staging-compute-engine-test.agentnoon.co/v1'

const STAGING_API = 'https://staging-api.agentnoon.co/v1'

const PRODUCTION_API = 'https://api.agentnoon.co/v1'
const AUS_PRODUCTION_API = 'https://api-aus.agentnoon.co/v1'
const EU_PRODUCTION_API_V2 = 'https://api-eu-v2.agentnoon.co/v1'
const DOHA_PRODUCTION_API = 'https://api-doha.agentnoon.co/v1'
const SINGAPORE_PRODUCTION_API = 'https://api-singapore.agentnoon.co/v1'
const AWS_STAGING_API = 'https://api-test.agentnoon.co/v1'
const AJZ_PRODUCTION_API = 'https://hcm.aljazeera.net/v1'

export const getRegion = () => {
  if (window.location.hostname.includes('hcm.aljazeera.net')) {
    return 'ajz'
  }
  try {
    if (
      firebase.auth().currentUser?.email.includes('@anglicare') ||
      firebase.auth().currentUser?.email.endsWith('aus@agentnoon.com') ||
      firebase.auth().currentUser?.email.endsWith('+aus@invisionsolutions.ca') ||
      firebase.auth().currentUser?.email.endsWith('@bevingtongroup.com') ||
      firebase.auth().currentUser?.email.endsWith('@deloitte.co.nz') ||
      // firebase.auth().currentUser?.email.endsWith('@originenergy.com.au') ||
      firebase.auth().currentUser?.email === 'agentnoon_service_aus@agentnoon.com'
    ) {
      return 'aus'
    }
    if (
      firebase.auth().currentUser?.email.includes('@csisolar') ||
      firebase.auth().currentUser?.email.includes('@canadiansolar.com') ||
      firebase.auth().currentUser?.email.includes('@nlng.com') ||
      firebase.auth().currentUser?.email.includes('nestle.com') ||
      firebase.auth().currentUser?.email.endsWith('@kindredgroup.com') ||
      firebase.auth().currentUser?.email.endsWith('@wearekindred.co.uk') ||
      firebase.auth().currentUser?.email.endsWith('@kindred.com') ||
      firebase.auth().currentUser?.email.endsWith('@rfu.com') ||
      firebase.auth().currentUser?.email.endsWith('eu@agentnoon.com') ||
      firebase.auth().currentUser?.email.endsWith('+eu@invisionsolutions.ca') ||
      firebase.auth().currentUser?.email === 'agentnoon_service_eu@agentnoon.com'
    ) {
      return 'eu'
    }
    if (
      firebase.auth().currentUser?.email === 'agentnoon_service_doha@agentnoon.com' ||
      firebase.auth().currentUser?.email.includes('agentnoon_test_service_doha@agentnoon.com') ||
      firebase.auth().currentUser?.email.includes('@etihad.ae') ||
      firebase.auth().currentUser?.email.endsWith('+doha@invisionsolutions.ca') ||
      firebase.auth().currentUser?.email.endsWith('doha@agentnoon.com') ||
      firebase.auth().currentUser?.email.includes('dohatest@agentnoon.com')
    ) {
      return 'doha'
    }
    if (
      firebase.auth().currentUser?.email === 'agentnoon_service_uae@agentnoon.com' ||
      firebase.auth().currentUser?.email.endsWith('uae@agentnoon.com') ||
      firebase.auth().currentUser?.email.endsWith('+uae@invisionsolutions.ca') ||
      firebase.auth().currentUser?.email.includes('uaetest@agentnoon.com')
    ) {
      return 'uae'
    }
    if (
      firebase.auth().currentUser?.email === 'agentnoon_service_singapore@agentnoon.com' ||
      firebase.auth().currentUser?.email.includes('@airasia.com') ||
      firebase.auth().currentUser?.email.endsWith('+singapore@invisionsolutions.ca') ||
      firebase.auth().currentUser?.email.endsWith('singapore@agentnoon.com') ||
      firebase.auth().currentUser?.email.includes('agentnoon_test_service_singapore@agentnoon.com')
    ) {
      return 'singapore'
    }
    if (firebase.auth().currentUser?.email.endsWith('aws-test@agentnoon.com')) {
      return 'aws-test'
    }
  } catch (e) {
    return 'us'
  }
  return 'us'
}

let STAGING_API_URL;

const getAPIUrl = () => {
  console.log('Using ', getRegion(), ' region')

  // return 'https://cloud-4guuofkcpa-uc.a.run.app'
  // At this time, window.MODE is not initialized yet. So just check the domain
  if (window.location.hostname.includes('localhost')) {
    console.log('RUNNING DEV MODE API points to localhost')
    return LOCAL_HOST
  }

  if (window.location.hostname.includes('production-preview')) {
    console.log('RUNNING PRODUCTION MODE API points to Production API')

    if (window.location.hostname === 'agentnoon--production-preview-lsn3jqo5.web.app') {
      console.log(`Using EU API V2 for ${EU_PRODUCTION_API_V2}`)
      return EU_PRODUCTION_API_V2
    }

    switch (getRegion()) {
      case 'eu':
        return EU_PRODUCTION_API_V2
      case 'aus':
        return AUS_PRODUCTION_API
      case 'singapore':
        return SINGAPORE_PRODUCTION_API
      case 'doha':
        return DOHA_PRODUCTION_API
      case 'aws-test':
        return AWS_STAGING_API
      case 'ajz':
        return AJZ_PRODUCTION_API
      default:
        return PRODUCTION_API
    }
  }

  if (window.location.hostname.includes('.web.app')) {
    console.log('RUNNING DEV MODE API points to staging API')
    // This app is running on a preview
    // This is where the backend API is hosted on.
    if (STAGING_API_URL) {
      console.debug("Using memoized staging API URL", STAGING_API_URL)
      return STAGING_API_URL
    }

    const REGEX = /--pr\d+/
    const PR_NUMBER = window.location.hostname.match(REGEX)[0].split('--')[1].slice(2)
    console.debug("PR_NUMBER", PR_NUMBER)

    STAGING_API_URL = `https://${PR_NUMBER}.pr-api.agentnoon.co/v1`
    console.log("Routing backend to", STAGING_API_URL)
    return STAGING_API_URL
  } else if (window.location.hostname.includes('staging')) {
    return STAGING_API;
  }

  // This is production if it made this far
  switch (getRegion()) {
    case 'eu':
      return EU_PRODUCTION_API_V2
    case 'aus':
      return AUS_PRODUCTION_API
    case 'singapore':
      return SINGAPORE_PRODUCTION_API
    case 'doha':
      return DOHA_PRODUCTION_API
    case 'aws-test':
      return AWS_STAGING_API
    case 'ajz':
      return AJZ_PRODUCTION_API
    default:
      return PRODUCTION_API
  }
}

export let API_URL = getAPIUrl()

export const getAuthHeader = async (forceRefresh = false) => {
  const token = await firebase.auth().currentUser?.getIdToken(forceRefresh)
  return { authorization: `Bearer ${token}` }
}

export const setAPIUrl = () => {
  console.log('setAPIUrl')
  API_URL = getAPIUrl()
}
