import { extractNumber } from '@/services/currency.service'
import * as Sentry from '@sentry/browser'
import { keyBy } from 'lodash-es'
import {
  addCompensation,
  addCompensations,
  bulkUpdateCompensations,
  getCompensations,
  updateCompensation,
  updateCompensations
} from '@/services/compensations-api.service.js'
import { errorBus, personUpdateBus } from '@/services/eventbus.service.js'

const state = {
  compensations: {},
  compensationByPersonId: {},
  isFetchingCompensations: {}
}

const getters = {
  compensations: (_state) => (boardId) => {
    return _state.compensations[boardId]?.filter((compensation) => !compensation?.deletedAt)
  },
  compensationForPerson:
    (_state) =>
    ({ boardId, personId }) => {
      return _state.compensationByPersonId[boardId]?.[personId] || null
    },
  salaryNumber:
    (_state, _getters) =>
    ({ boardId, personId }) => {
      const payRate = _getters.compensationForPerson({ boardId, personId })?.payRate
      return extractNumber(payRate)
    },
  salaryHistory:
    (_state, _getters) =>
    ({ boardId, personId }) => {
      const previousRates =
        _getters.compensationForPerson({ boardId, personId })?.previousRates || []
      return previousRates?.map((salary) => extractNumber(salary.payRate))
    },
  compensationByPersonId: (_state) => (boardId) => _state.compensationByPersonId[boardId] || {},
  isFetchingCompensations: (_state) => (boardId) => _state.isFetchingCompensations[boardId] ?? false
}

const mutations = {
  setCompensations(_state, { data, boardId }) {
    _state.compensations[boardId] = data
    _state.compensationByPersonId[boardId] = keyBy(data, 'personId')
  },
  addCompensation(_state, { data, boardId }) {
    if (!_state.compensations[boardId]) _state.compensations[boardId] = []
    if (!_state.compensationByPersonId[boardId]) _state.compensationByPersonId[boardId] = {}

    _state.compensations[boardId].push(data)
    _state.compensationByPersonId[boardId][data.personId] = data
  },
  setCompensation(_state, { data, boardId }) {
    const index = _state.compensations[boardId].findIndex(
      ({ personId }) => data.personId === personId
    )

    if (index === -1) {
      _state.compensations[boardId].push(data)
    } else {
      _state.compensations[boardId][index] = data
    }

    _state.compensationByPersonId[boardId][data.personId] = data
  },
  updateCompensations(_state, { compensations, boardId }) {
    //find exising compensations first

    const compIdToIndex = {}
    _state.compensations[boardId].forEach((comp, index) => {
      compIdToIndex[comp.id] = index
    })

    compensations.forEach((comp) => {
      const index = compIdToIndex[comp.id]
      if (index === undefined) {
        _state.compensations[boardId].push(comp)
      } else {
        _state.compensations[boardId][index] = comp
      }
    })

    _state.compensationByPersonId[boardId] = keyBy(_state.compensations[boardId], 'personId')
  },
  setIsFetchingCompensations(_state, { boardId, status }) {
    _state.isFetchingCompensations[boardId] = status
  }
}

const actions = {
  async fetchNew(context, { boardId, force = true }) {
    if (context.state.compensations[boardId] && !force) return false
    if (context.state.isFetchingCompensations[boardId]) return false

    await context.dispatch('fetchCompensations', { boardId })
    return true
  },

  async fetchCompensations(context, { boardId, force = true }) {
    try {
      if (!force && context.getters.compensations(boardId)?.length > 0) return
      if (!force && context.getters.isFetchingCompensations(boardId)) return

      context.commit('setIsFetchingCompensations', { boardId, status: true })

      const compensations = await getCompensations({ boardId })

      context.commit('setCompensations', { data: compensations, boardId })
      context.commit('setIsFetchingCompensations', { boardId, status: false })
    } catch (e) {
      console.error(e)
      Sentry?.captureException(e)
      context.commit('setIsFetchingCompensations', { boardId, status: false })
    }
  },

  async addCompensation(
    context,
    { personId, boardId, payPer, payRate, payRateEffectiveDate, payType, currency }
  ) {
    if (!boardId) throw new Error('BoardId is undefined')

    const newData = {
      personId,
      boardId,
      payPer,
      payRate,
      payRateEffectiveDate,
      payType,
      currency
    }

    context.commit('addCompensation', { data: newData, boardId })

    try {
      await addCompensation({ boardId, compensation: newData })
    } catch (e) {
      console.error('Error adding new compensation: ', e)
      if (Sentry) Sentry.captureException(e)
      errorBus.emit({ type: 'sync', message: e })
    }
  },

  async updateCompensation(
    context,
    {
      personId,
      boardId,
      payPer = null,
      payRate = null,
      payRateEffectiveDate = null,
      payType = null,
      currency = null
    }
  ) {
    if (!boardId) throw new Error('BoardId is undefined')

    const previousCompensation = context.getters.compensationForPerson({ boardId, personId })

    try {
      const newData = {
        ...(previousCompensation || {}),
        personId,
        boardId,
        payPer,
        payRate,
        payRateEffectiveDate,
        payType,
        currency,
        previousRates: [
          ...(previousCompensation?.previousRates || []),
          {
            payRate: previousCompensation?.payRate || null,
            payType: previousCompensation?.payType || null,
            currency: previousCompensation?.currency || null
          }
        ]
      }

      context.commit('setCompensation', { data: newData, boardId })

      let compensation = await updateCompensation({ boardId, personId, compensation: newData })

      if (!compensation) {
        compensation = await addCompensation({ boardId, compensation: newData })
      }

      personUpdateBus.emit({ type: 'compensation', compensation })
    } catch (e) {
      console.error('Error updating compensation: ', e)
      if (Sentry) Sentry.captureException(e)
      errorBus.emit({ type: 'sync', message: e })
    }
  },

  async updateCompensations(context, { boardId, compensations }) {
    if (!boardId) throw new Error('BoardId is undefined')

    try {
      // eslint-disable-next-line no-unused-vars
      const comps = await updateCompensations({ boardId, compensations })
      //TODO: update existing comps based on comps return obj
      await context.dispatch('fetchCompensations', { boardId })
    } catch (e) {
      console.error('Error updating compensation: ', e)
      if (Sentry) Sentry.captureException(e)
      errorBus.emit({ type: 'sync', message: e })
    }
  },

  async bulkUpdateCompensations(context, { boardId, peopleIds, compensation }) {
    if (!boardId) throw new Error('BoardId is undefined')

    try {
      await bulkUpdateCompensations({ boardId, compensation, peopleIds })
      await context.dispatch('fetchCompensations', { boardId })
    } catch (e) {
      console.error('Error updating compensation: ', e)
      if (Sentry) Sentry.captureException(e)
      errorBus.emit({ type: 'sync', message: e })
    }

    //TODO add personUpdateBus for bulk operations
  },

  async createCompensations(context, { boardId, compensations }) {
    if (!boardId) throw new Error('BoardId is undefined')
    if (!compensations || compensations.length === 0) return

    try {
      const comps = await addCompensations({ boardId, compensations })
      comps?.forEach((comp) => {
        context.commit('addCompensation', { data: comp, boardId })
      })
    } catch (e) {
      console.error('Error creating compensation: ', e)
      if (Sentry) Sentry.captureException(e)
      errorBus.emit({ type: 'sync', message: e })
    }
  },

  async updateCompensationCurrency(context, { personId, boardId, currency }) {
    if (!boardId) throw new Error('BoardId is undefined')

    const previousCompensation = context.getters.compensationForPerson({ boardId, personId })
    try {
      const newData = {
        ...previousCompensation,
        currency,
        previousRates: [
          ...(previousCompensation.previousRates || []),
          {
            payRate: previousCompensation?.payRate || null,
            payType: previousCompensation?.payType || null,
            currency: previousCompensation?.currency || null
          }
        ]
      }

      const compensation = await updateCompensation({ boardId, personId, compensation: newData })
      context.commit('setCompensation', { data: compensation, boardId })
      personUpdateBus.emit({ type: 'compensation', compensation })
    } catch (e) {
      window.console.error('Error updating compensation: ', e)
      if (Sentry) Sentry.captureException(e)
      errorBus.emit({ type: 'sync', message: e })
    }
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
